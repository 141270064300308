<template>
  <!-- prettier-ignore -->
  <div id="navbar" class="navbar p-1 px-md-3 w-100" ref="navbarContainer" :class="{'sticky-navbar': sticky}">
    <!-- Search input -->
    <!-- <div id="search" class="search px-md-0 w-100" ref="search">
      <div class="search-cont position-relative">
        <input class="search-input" type="text"
        placeholder="Buscar platillos y bebidas"
        v-model="value" @keyup="search" />
        <span class="material-icons position-absolute start-0 top-50 translate-middle-y px-1"
        >search</span>
      </div>
    </div> -->

    <!-- Mobile Navbar -->
    <div id="navbar-mobile" class="d-lg-none w-100" v-if="isMobile">
      <div class="d-flex">
        <div class="sucursal-image me-1" :class="{'d-none': !sticky}">
          <img class="rounded-circle" data-bs-toggle="modal" data-bs-target="#sucursalInfoModal" :src="sucursal.logo || require('@/assets/images/profile.png')"
            :alt="`${sucursal.company} ${sucursal.name}`"/>
        </div>
        <div class="d-flex flex-grow-1 flex-column justify-content-between">
          <div class="w-100 d-flex justify-content-between align-self-end flex-grow align-items-center position-relative" :class="{'d-none': !sticky}">

            <div id="search" class="search px-md-0 position-absolute" :class="{'w-100': searchActive}" ref="search">
              <div class="search-cont position-relative">
                <input class="search-input" :class="{'sticky-color': sticky}" type="text"
                placeholder="Buscar platillos y bebidas"
                v-model="value" @keyup="search" />
                <span class="material-icons position-absolute start-0 top-50 translate-middle-y px-1"
                >search</span>
                <span class="material-symbols-outlined position-absolute end-0 top-50 translate-middle-y px-1" @click="searchActive = false">close</span>
              </div>
            </div>
          </div>

          
          <!-- <div class="d-lg-none sucursal-info-slide hide-scrollbar w-100 px-1 overflow-auto" :class="{'d-none': !sticky}" style="margin-bottom: 4px">
            <ul class="info-nav nav-pills d-flex m-0" style="white-space: nowrap">
                <li class="nav-item me-1" v-for="(itm, index) in info" :key="index" v-show="itm.value || itm.type === 'triggerModal'">
                  <div class="nav-link d-flex align-items-center" v-if="itm.type === 'triggerModal'" data-bs-toggle="modal" :data-bs-target="itm.target">
                    <span :class="{'me-1': itm.label}"  v-html="itm.icon"></span>
                    {{itm.label}}
                  </div>
                  <a class="nav-link d-flex align-items-center" v-else :href="`${itm.value}`" target="_blank">
                    <span :class="{'me-1': itm.label}" :style="!itm.label?'font-size: 21px':''"  v-html="itm.icon"></span>
                    {{itm.label}}
                  </a>
                </li>
              </ul>
          </div> -->

          <div class="families-bar d-flex align-items-center px-md-0">
            <!-- Search button -->
            <div class="material-symbols-outlined py-1 d-lg-none" style="padding: 0 4px; font-size: 28px" ref="searchBtn" @click="searchActive = true" :class="{'d-none': !sticky}">
                search
            </div>
            <!-- Burger button -->
            <div class="nav-item material-icons py-1 me-1 d-lg-none" style="padding: 0 4px; font-size: 28px"
              data-bs-toggle="modal" data-bs-target="#modalMenu"
            >menu</div>
            <!-- Categories-->
            <div class="dropdown flex-grow-1" style="font-size: 1.4rem">
              <a class="nav-item btn btn-outline btn-lg dropdown-toggle w-100" :class="{'sticky-color': sticky}"
                style="padding: 13px;" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                {{ activeFamily }}
              </a>
    
              <ul class="dropdown-menu py-1 w-100" aria-labelledby="dropdownMenuLink">
                <li v-show="promotions.length"><a class="dropdown-item" ref="promo" href="#promo" @click="activeFamily = 'Promociones'">Promociones</a></li>
                <li v-for="(family, index) in families" :key="index"><a class="dropdown-item family" :href="`#fam${family.id}`" @click="activeFamily = family.name">{{ family.name }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Subcategories -->
      <div class="families hide-scrollbar pt-1"
        ref="subNavbar" v-if="categories.length > 0">
        <ul class="families-nav nav-pills d-flex m-0">
            <li class="nav-item" ref="category" v-for="(category, index) in categories" :key="index">
              <a class="category nav-link" :class="{'sticky-color': sticky}" :href="`#cat${category.id}`">
                {{ category.name }}</a>
            </li>
          </ul>
      </div>
    </div>

    <!-- Desktop aside -->
    <div class="accordion d-none d-lg-block px-3 pb-5 mt-3"
      id="accordionExample" v-if="!isMobile">
      <div class="accordion-item"
        v-for="(family, index) in families" :key="index"
        :style="`${family.categories.length === 0 ? 'display: none' : ''}`">
        <h3 class="accordion-header" :id="`menu-item-heading-${index}`">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${family.id}`"
            aria-expanded="false"
            aria-controls="collapseOne">
            <div class="me-1" style="width: 36px; height: 36px">
            <img v-if="family.icon" class="me-1" :src="family.icon" alt="" />
            </div>
            {{ family.name }}
          </button>
        </h3>

        <!-- Collapsed area with categories -->
        <div
          :id="`collapse${family.id}`"
          class="accordion-collapse collapse pt-1"
          :aria-labelledby="`menu-item-heading-${index}`"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body pt-0 pb-0">
            <ul>
              <li class="ps-5"
                v-for="(category, index) in family.categories"
                :key="index">
                <a :href="`#cat${category.id}`">
                  {{ category.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>  <!-- End accordion item -->
    </div>  <!-- End desktop aside -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  mounted() {
    window.addEventListener("scroll", () => {
      if (this.isMobile) {
        this.stickyNavbar();
        if (!this.scrolling) this.subCatScrollSpy();
      }
    });
    window.addEventListener("scrollend", () => {
      this.scrolling = false;
      //   this.subCatScrollSpy();
    });

    // Only compatible width bootstrap 5.1.3
    window.addEventListener("activate.bs.scrollspy", () => {
      this.setActiveFamily();
    });
  },
  data() {
    return {
      activeFamily: "Promociones",
      activeCategory: "",
      // categories: [],
      value: "",
      scrolling: false,
      active: 0,
      sticky: false,
      searchActive: false,
    };
  },
  props: {
    sucursal: Object,
    families: Array,
    info: Array,
    isMobile: Boolean,
  },
  watch: {
    searchActive(v) {
      if (!v) {
        this.value = "";
      }
    },
    activeFamily() {
      this.scrolling = true;
    },
  },
  computed: {
    promotions() {
      return JSON.parse(this.$store.getters.promotions);
    },
    categories() {
      let categories = [];
      if (this.families.length) {
        this.families.forEach((f) => {
          categories = categories.concat(f.categories);
        });
        return categories;
      }

      return [];
    },
  },
  methods: {
    search() {
      this.$parent.$parent.$refs.searchResult.$el.classList.remove(
        "max-height-0"
      );
      this.$emit("keyup", this.value);
    },
    stickyNavbar() {
      const navbarHeight = 56;
      const hero = this.$parent.$refs.sucursal;

      let offset = hero.offsetTop + hero.clientHeight - navbarHeight;

      if (window.scrollY > offset) {
        this.$refs.navbarContainer.classList.add("navbar--top");
        // this.$refs.search.style = "height: 46px";
        this.sticky = true;
        this.$parent.$refs.sucursal.style = "margin-bottom: 38px";
      } else {
        this.$refs.navbarContainer.classList.remove("navbar--top");
        this.sticky = false;
        // setTimeout(() => {
        if (window.scrollY === 0) {
          this.searchActive = false;
        }
        // }, 500);
        // this.$refs.search.style = "height: 0; margin-bottom: 0";
        this.$parent.$refs.sucursal.style = "margin-bottom: 0";
        this.value = null;
        this.$parent.$parent.$refs.searchResult.$el.classList.add(
          "max-height-0"
        );
      }
    },
    setActiveFamily() {
      const navItems = document.querySelectorAll(".family");

      navItems.forEach((item, index) => {
        if (item.classList.contains("active")) {
          this.active = index;
          this.activeFamily = this.families[index].name;
          // this.categories = this.families[index].categories;
          this.$refs.category.forEach((c) => {
            c.classList.remove("active");
          });
          // if (this.$refs.subNavbar) {
          //   setTimeout(() => {
          //     this.$refs.subNavbar.scrollTo(0, 0);
          //   }, 2000);
          // }
        } else if (this.$refs.promo.classList.contains("active")) {
          this.activeFamily = "Promociones";
          // this.categories = [];
        }
      });
    },
    scrollToNavItemActive() {
      const navItems = document.querySelectorAll(".family");
      // Init scroll with first element size + margin
      let scrollNav = this.$refs.promo.clientWidth + 8;

      navItems.forEach((item, index) => {
        if (item.classList.contains("active")) {
          this.active = index;
          // this.categories = this.families[index].categories;
        } else if (this.$refs.promo.classList.contains("active")) {
          // this.categories = [];
        }
      });

      // Wait till href scrolling end
      setTimeout(() => {
        navItems.forEach((item) => {
          if (item.classList.contains("active")) {
            // Reposition new navItem active
            this.$refs.navbar.scrollTo(scrollNav, 0);
          } else if (this.$refs.promo.classList.contains("active")) {
            // Reset scroll
            this.$refs.navbar.scrollTo(0, 0);
          }
          // Sum new navItem active size + margin
          scrollNav += item.clientWidth + 8;
        });
      }, 800);
    },
    subCatScrollSpy() {
      // list = [ProductList, ...]
      this.$parent.$parent.$refs.list.forEach((item) => {
        // list2 = ProductList[category, ...]
        if (item.$refs.list2 !== undefined) {
          // sCatSection = category section with product list
          item.$refs.list2.forEach((sCatSection) => {
            // getBoundingClientRect().top = distance between top and element
            if (
              (sCatSection.getBoundingClientRect().top < 300 &&
                sCatSection.getBoundingClientRect().top > 0) ||
              (Math.abs(sCatSection.getBoundingClientRect().top) <
                sCatSection.clientHeight &&
                sCatSection.getBoundingClientRect().top < 0)
            ) {
              if (this.$refs.category) {
                this.$refs.category.forEach((navItem) => {
                  if (navItem.classList.contains("active")) {
                    navItem.classList.remove("active");
                  }
                });
              }

              if (
                !document
                  .querySelector(`a[href='#${sCatSection.id}']`)
                  .parentElement.classList.contains("active")
              ) {
                // Highlight navItem with id category section
                document
                  .querySelector(`a[href='#${sCatSection.id}']`)
                  .parentElement.classList.add("active");

                const index = this.categories.findIndex(
                  (c) => c.id === parseInt(sCatSection.id.replace("cat", ""))
                );

                if (this.activeCategory !== sCatSection.id) {
                  let scrollWidth = 0;
                  for (var i = 0; i < index; i++) {
                    scrollWidth += this.$refs.category[i].clientWidth;
                  }

                  this.$refs.subNavbar.scrollTo(scrollWidth, 0);
                }

                this.activeCategory = sCatSection.id;
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-i {
  font-size: 1.6rem;
  border: 2px solid var(--primary-font-cl);
  border-radius: 50%;
}
.search {
  justify-content: flex-end;
  overflow: hidden;
  // height: 0;
  z-index: 1000;
  width: 0;
  right: 0;
  top: -4px;
  transition: width 0.3s;
  .search-input {
    padding-left: 40px;
    padding-right: 24px;
    width: 100%;
    background-color: var(--secondary-bg);
    color: var(--primary-font-cl);
    font-size: 16px;
    outline: none;
    border: 0;
    height: 49px;
    border-radius: 10px;
    box-shadow: 5px 8px 10px -9px rgba(0, 0, 0, 0.25);
    &::-webkit-input-placeholder {
      text-align: center;
      color: var(--secondary-font-cl);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      text-align: center;
      color: var(--secondary-font-cl);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center;
      color: var(--secondary-font-cl);
    }
    &:-ms-input-placeholder {
      text-align: center;
      color: var(--secondary-font-cl);
    }
  }
  span {
    color: var(--primary-font-cl);
  }
}
.navbar {
  height: fit-content;
  bottom: 0;
  background: var(--primary-bg);
  &--top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
.nav-item {
  // background: var(--secondary-bg);
  border-radius: 8px;
  // margin-right: 8px;
  box-shadow: 5px 8px 10px -9px rgba(0, 0, 0, 0.25);
}
.families {
  overflow: auto;
  &-nav {
    width: fit-content;
    .nav-item {
      // background: var(--secondary-bg);
      border-radius: 8px;
      // margin-right: 8px;
      padding-right: 8px;
      white-space: nowrap;
      line-height: 1;
      display: flex;
      align-items: center;
      &:last-child {
        margin: 0;
      }
      .nav-link {
        background: var(--secondary-bg);
        color: var(--primary-font-cl);
        display: block;
        border-radius: 8px;
        padding: 15px 16px;
      }
    }
    .active .nav-link {
      background-color: var(--primary) !important;
      color: var(--primary-bg) !important;
      font-weight: 600;
    }
  }
}
.dropdown {
  .nav-item {
    border: 1px solid var(--primary);
  }
  &-toggle {
    background: var(--secondary-bg);
  }
  &-menu {
    background: var(--secondary-bg);
    .active {
      background: var(--primary) !important;
      color: var(--primary-bg) !important;
    }
  }
  &-item {
    color: var(--primary-font-cl);
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
    &:hover {
      background: var(--primary);
      color: var(--primary-bg);
    }
  }
}
.nav-link:hover {
  color: var(--primary-font-cl);
  background: var(--secondary-bg);
}
.accordion {
  &-item {
    border: 0;
  }
  &-button {
    font-family: $body-ft;
    font-size: $ft-sm;
    &:focus {
      border: 0;
      box-shadow: none;
    }
    img {
      width: 36px;
    }
  }
  &-body li {
    padding-bottom: 5px;
    a {
      font-size: $ft-sm;
      color: var(--secondary-font-cl);
    }
  }
}
.sticky-color {
  background: var(--primary-bg) !important;
  color: var(--secondary-font-cl) !important;
}
.sticky-navbar {
  background: var(--secondary-bg) !important;
}

.sucursal-image {
  max-width: 45px;
}

.btn:hover {
  background-color: var(--primary);
  color: var(--primary-bg);
  font-weight: 600;
}

.branding-page img {
  width: 28px;
}

@media (min-width: $desktop-width) {
  .search {
    height: 45px;
  }
  .navbar {
    position: initial;
  }
}

.navbar {
  .nav {
    &-link {
      color: var(--primary-font-cl);
    }
    .active {
      background: red;
    }
  }
}
</style>