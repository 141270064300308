/** Styles */
import "normalize.css";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/icons/style.css";
import "./assets/fontawesome/css/all.min.css";
/** Vue */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueHead from "vue-head";

Vue.use(VueHead);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
