var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-md-0",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modalProduct"},on:{"click":function($event){return _vm.productClick({ prodId: _vm.product.id, subcatId: _vm.subcatId })}}},[(_vm.product.featured)?_c('div',{staticClass:"product product-featured mt-1"},[_c('div',{staticClass:"product-image p-0 col-lg-6"},[_c('img',{staticClass:"lazy-load",attrs:{"src":_vm.product.thumbnail,"alt":_vm.product.name}})]),_c('div',{staticClass:"product-details position-relative p-2 pb-1 md-min-height"},[_c('div',{staticClass:"d-flex justify-content-between mb-1 align-items-center"},[(_vm.product.recommended)?_c('span',{staticClass:"badge rounded-pill"},[_vm._v("Destacado")]):_vm._e(),_c('ul',{staticClass:"d-flex"},[_vm._l((_vm.maxTags),function(t,i){return _c('li',{key:i,staticClass:"tag-dot rounded-circle"},[_c('span',{class:t.icon})])}),(_vm.moreTags.length)?_c('li',{staticClass:"tag-dot rounded-circle fw-bold"},[_c('span',{staticStyle:{"margin-left":"-2px"}},[_vm._v("+"+_vm._s(_vm.moreTags.length))])]):_vm._e()],2)]),_c('div',{staticClass:"d-flex justify-content-between align-items-end mb-1"},[_c('span',{staticClass:"product-title col-12 mb-1 d-block",domProps:{"innerHTML":_vm._s(
            `${_vm.product.name}`
          )}})]),(_vm.product.description)?_c('p',{staticClass:"product-description mb-0",domProps:{"innerHTML":_vm._s(
          `${_vm.product.description?_vm.product.description.slice(0, 150): ''}${
            _vm.product.description && _vm.product.description.length > 150 ? '...' : ''}`
        )}}):_c('ul',{staticClass:"product-description mb-0"},_vm._l((_vm.product.variant_groups),function(g,index){return _c('li',{key:index,class:!g.variants.length?'d-none':''},[_c('p',[_vm._v(_vm._s(g.name))]),_vm._l((g.variants),function(v,i){return _c('small',{key:i,class:!v.enabled && v.enabled !== undefined?'d-none':'d-block'},[_vm._v(" "+_vm._s(v.name)+" "+_vm._s(v.price?`$${v.price}` : '')+" ")])})],2)}),0),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-1"},[_c('span',{staticClass:"product-price d-block col-2"},[_vm._v("$"+_vm._s(_vm.product.price))]),_c('button',{staticClass:"btn btn-outline rounded-pill"},[_vm._v("Ver más")])])])]):_c('div',{staticClass:"product mt-1 d-flex"},[_c('div',{staticClass:"product-details position-relative p-2 pe-1 pb-1 d-flex flex-column justify-content-between flex-grow-1",class:{'col-8': _vm.product.thumbnail}},[_c('div',{staticClass:"d-flex justify-content-between mb-1 align-items-center"},[(_vm.product.recommended)?_c('span',{staticClass:"badge rounded-pill",staticStyle:{"width":"fit-content"}},[_vm._v("Recomendado")]):_vm._e(),_c('ul',{staticClass:"d-flex"},[_vm._l((_vm.maxTags),function(t,i){return _c('li',{key:i,staticClass:"tag-dot rounded-circle"},[_c('span',{class:t.icon})])}),(_vm.moreTags.length)?_c('li',{staticClass:"tag-dot rounded-circle fw-bold"},[_c('span',{staticStyle:{"margin-left":"-2px"}},[_vm._v("+"+_vm._s(_vm.moreTags.length))])]):_vm._e()],2)]),_c('div',{},[_c('span',{staticClass:"product-title col-12 mb-1 d-block",domProps:{"innerHTML":_vm._s(
            `${_vm.product.name}`
          )}}),(_vm.product.description)?_c('p',{staticClass:"product-description mb-0",domProps:{"innerHTML":_vm._s(
          `${_vm.product.description?_vm.product.description.slice(0, 70): ''}${
            _vm.product.description && _vm.product.description.length > 70 ? '...' : ''}`
        )}}):_c('ul',{staticClass:"product-description mb-0"},_vm._l((_vm.product.variant_groups),function(g,index){return _c('li',{key:index,class:!g.variants.length?'d-none':''},[_c('p',[_vm._v(_vm._s(g.name))]),_vm._l((g.variants),function(v,i){return _c('small',{key:i,class:!v.enabled && v.enabled !== undefined?'d-none':'d-block'},[_vm._v(" "+_vm._s(v.name)+" "+_vm._s(v.price?`$${v.price}` : '')+" ")])})],2)}),0)]),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-1"},[_c('span',{staticClass:"product-price d-block col-2"},[_vm._v("$"+_vm._s(_vm.product.price))]),_c('button',{staticClass:"btn btn-outline rounded-pill"},[_vm._v("Ver más")])])]),(_vm.product.thumbnail)?_c('div',{staticClass:"product-image col-4 p-0"},[_c('img',{staticClass:"lazy-load",attrs:{"src":_vm.product.thumbnail,"alt":_vm.product.name}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }