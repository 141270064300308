<template>
  <!-- prettier-ignore  -->
  <section class="section position-relative pt-3">
    <!-- Ghost element that set limit between top and section for href scrolling  -->
    <div class="limit position-absolute top-0"
      :id="`fam${prodByCat.id}`"></div>
    <h2 class="title px-1 px-md-0">{{ prodByCat.name }}</h2>
    <div class="products position-relative" v-if="recommended.length">
      <h3 class="subtitle px-1 px-md-0 pt-2">Recomendaciones</h3>
      <div class="slide-products position-relative" ref="parentSlide">
        <ul class="row products-list ps-2 ps-lg-1 flex-nowrap hide-scrollbar"
        :ref="`slide${prodByCat.id}`">
          <ProductCardSquare
          class="slide-child"
            v-for="(product, j) in recommended"
            :key="j" :product="product"
            @click="productClick"
            :index="j" :subcatId="product.category_id" />
          </ul>
          <button
      v-if="recommended.length > 2"
            class="carousel-control-prev snap-scroll d-none d-lg-block"
            type="button"
            @click="snapSlide('prev')"
          >
            <span
              class="carousel-control-prev-icon"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
      v-if="recommended.length > 2"
            class="carousel-control-next snap-scroll d-none d-lg-block"
            type="button"
            @click="snapSlide('next')"
          >
            <span
              class="carousel-control-next-icon"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
      </div>
    </div>
    <div class="products position-relative products-grid"
      v-for="(category, i) in prodByCat.categories"
      :key="i">
      <div class="limit position-absolute top-0"
        :id="`cat${category.id}`"
        ref="list2"></div>
      <h3 class="subtitle px-1 px-md-0 pt-2">{{ category.name }}</h3>
      <p v-if="category.description" class="fs-3 fw-bold mt-1 mt-lg-2 px-1 px-md-0" style="line-height: 1.4">{{category.description}}</p>
      <ul class="products-list ps-1 ps-md-0">
        <ProductCard
          v-for="(product, j) in category.products"
          :key="j" :product="product"
          @click="productClick"
          :index="j" :subcatId="category.id" />
      </ul>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import ProductCardSquare from "@/components/ProductCardSquare";
export default {
  name: "ProductList",
  components: {
    ProductCard,
    ProductCardSquare,
  },
  mounted() {
    // console.log('Products list', this.prodByCat)
  },
  props: {
    prodByCat: Object,
  },
  data() {
    return {
      slide: 1,
    };
  },
  computed: {
    recommended() {
      let products = [];

      if (this.prodByCat.categories.length) {
        this.prodByCat.categories.forEach((c) => {
          products = products.concat(c.recommended);
        });
      }

      return products;
    },
    //   categories() {
    //     return this.prodByCat.categories.filter((c) => c.products.length );
    //   },
  },
  methods: {
    productClick(props) {
      if (props.recommended) {
        props.index = this.recommended.findIndex((p) => p.id === props.prodId);
      }

      props.catId = this.prodByCat.id;
      this.$emit("click", props);
    },
    snapSlide(side) {
      const childWidth = (this.$refs.parentSlide.clientWidth / 12) * 5;
      if (side === "next" && this.recommended.length > this.slide) {
        setTimeout(() => {
          this.$refs[`slide${this.prodByCat.id}`].scrollBy(childWidth, 0);
          this.slide++;
        }, 200);
      } else if (side === "prev" && this.slide > 1) {
        setTimeout(() => {
          this.$refs[`slide${this.prodByCat.id}`].scrollBy(-childWidth, 0);
          this.slide--;
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.limit {
  transform: translateY(-100%);
  height: 140px;
}
.slide-products {
  ul {
    overflow-x: scroll;
  }
}
@media (min-width: $desktop-width) {
  .slide-products {
    ul {
      scroll-snap-type: x mandatory;
      .slide-child {
        scroll-snap-align: start;
      }
    }
  }
  .snap-scroll {
    width: 50px;
    height: 100px;
    top: 50%;
    background: #000;
  }
  .carousel-control-prev {
    transform: translateY(-50%);
  }
  .carousel-control-next {
    transform: translateY(-50%);
  }
  .products-grid {
    ul {
      columns: 2;
      column-gap: 0.8rem;
    }
  }
}
</style>