<template>
  <!-- prettier-ignore -->
  <section class="promo position-relative pt-3">
    <div id="promo" class="position-absolute top-0"></div>
    <h2 class="title ps-1 ps-md-0 mb-1">Promociones</h2>
    <p class="ps-1 ps-lg-0 mt-1">Haz click en las promociones para ver más detalles</p>
    <div class="slide-promotions promo-gallery d-flex hide-scrollbar row ps-2 px-md-0 position-relative" ref="parentSlide">
      <div class="row promotions-list flex-nowrap hide-scrollbar" ref="listSlide">
        <div class="promo-item col-7 col-md-3"
          v-for="(promo, index) in promotions" :key="index"
          data-bs-toggle="modal" data-bs-target="#modalPromo"
          @click="promoClick(index)">
          <img class="promo-lazy-load" src="" :data-src="promo.thumbnail" :alt="promo.name"></div>
      </div>
      
      <button
      v-if="promotions.length > 3"
            class="carousel-control-prev snap-scroll d-none d-lg-block"
            type="button"
            @click="snapSlide('prev')"
          >
            <span
              class="carousel-control-prev-icon"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
      v-if="promotions.length > 3"
            class="carousel-control-next snap-scroll d-none d-lg-block"
            type="button"
            @click="snapSlide('next')"
          >
            <span
              class="carousel-control-next-icon"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
    </div>
  </section>
</template>

<script>
import lozad from "lozad";

export default {
  name: "PromoGallery",
  mounted() {
    let elmts = document.querySelectorAll(".promo-lazy-load");

    window.img_observer = lozad(elmts);
    window.img_observer.observe();
  },
  props: {
    promotions: Array,
  },
  data() {
    return {
      slide: 1,
    };
  },
  methods: {
    promoClick: function (index) {
      this.$emit("click", index);
    },
    snapSlide(side) {
      const childWidth = (this.$refs.parentSlide.clientWidth / 12) * 3;
      if (side === "next" && this.promotions.length > this.slide) {
        setTimeout(() => {
          this.$refs.listSlide.scrollBy(childWidth, 0);
          this.slide++;
        }, 200);
      } else if (side === "prev" && this.slide > 1) {
        setTimeout(() => {
          this.$refs.listSlide.scrollBy(-childWidth, 0);
          this.slide--;
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#promo {
  transform: translateY(-100%);
  height: 100px;
}
.promo {
  &-gallery {
    overflow: auto;
    flex-wrap: nowrap;
    // min-height: 230px;
  }
  &-item {
    padding: 0;
    // min-width: 150px;
    // max-width: 150px;
    margin-right: 8px;
    img {
      width: 100%;
      // width: 150px;
      // height: 230px;
      background: var(--secondary-bg);
    }
  }
}
.slide-promotions {
  .promotions-list {
    overflow-x: scroll;
  }
}
@media (min-width: $desktop-width) {
  .slide-promotions {
    .promotions-list {
      scroll-snap-type: x mandatory;
      .slide-child {
        scroll-snap-align: start;
      }
    }
  }
  .snap-scroll {
    width: 50px;
    height: 100px;
    top: 50%;
    // background: rgba(0, 0, 0, 1);
    background: #000;
  }
  .carousel-control-prev {
    transform: translateY(-50%);
  }
  .carousel-control-next {
    transform: translateY(-50%);
  }
  .promotions-grid {
    ul {
      columns: 2;
      column-gap: 0.8rem;
    }
  }
}
</style>